import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import HomePortfolioItem from "../HomePortfolioItem";


const HomePortfolio = ({ portfolioData }) => {


  return (
      <section className="homepage-grid">
        <div className="container-fluid">
          <div className="site-container">
            <div className="row">
              {portfolioData.map((item, index) => {

                return (
                  <HomePortfolioItem key={index} item={item} />
                )
              })}
              <div className="homepage-grid__col homepage-grid__col__cta">
                <Link className="homepage-grid__cta" to="/portfolio/">zobacz<br></br> więcej</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default HomePortfolio;