import "./styles.scss";
import 'swiper/css';
import 'swiper/css/autoplay';

import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';



const ClientsSlider = ({ logos }) => {


  SwiperCore.use([Autoplay]);

    const params = {
      loop: true,
      autoplay: {
        delay: 3000
      },
      slidesPerView: 2,
      spaceBetween: 20,
      centeredSlides: true,
      breakpoints: {
        587: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: true,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
          centeredSlides: false,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 30,
          centeredSlides: false,
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 30,
          centeredSlides: false,
        }
      },
      scrollbar: {
        draggable: true,
      }
    }

    return (
    <section className="clients-slider">
      <div className="site-container">
        <div className="section-title">Klienci</div>
          <Swiper {...params}>
          {logos.map((logo, index) => {
            
            return (
              <SwiperSlide key={index}>
                <img src={logo?.logosItem?.sourceUrl} alt={logo?.logosItem?.altText} /></SwiperSlide>
            )
          })
        }
        </Swiper>
      </div>
    </section>
  );
};

export default ClientsSlider;