import "styles/pages/home.scss";

import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout"
import ImagesBar from "components/ImagesBar";
import ClientsSlider from "components/ClientsSlider";
import Counter from "components/Counter";
import LeftSideText from "components/LeftSideText";
import HomePortfolio from "../page_components/home/HomePortfolio";


let interval;

const HomePage = ({ data }) => {
  
  const seo = data.homePage?.nodes[0]?.seo;
  const home_page_data = data.homePage?.nodes[0]?.pageHome;

  const homeRef = useRef(null);

  const header_setup = {logo:'logo.svg', fontColor:'#ffffff', navbarColor: 'transparent'};
  const header_setup_down = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};

  const [slideIndex, setSlideIndex] = useState(0);
  const [isHidden, setHidded] = useState(false);
  const [isColored, setColored] = useState(false);

  const navbar_setup = isColored ? header_setup_down : header_setup;
  
  const homepage_meet_content = 
    {
      aboutListMain: home_page_data.meetInfo,
      aboutListHeading: home_page_data.meetHeading,
      aboutListText: home_page_data.meetText,
      aboutListBtn: home_page_data.meetBtn,
      aboutListLink: home_page_data.meetLink,
      aboutListImg: home_page_data.meetImg
    }
  
  function handleNavbar(e) {
    setHidded(window.pageYOffset > 5 && window.pageYOffset + 80 < homeRef.current.clientHeight)
    setColored(window.pageYOffset + 80 >= homeRef.current.clientHeight)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleNavbar);
    clearInterval(interval);
    interval = setInterval(() => {
      setSlideIndex((currentSlideIndex) =>
        currentSlideIndex + 1 > 2 ? 0 : currentSlideIndex + 1
      );
    }, 10000);
  
    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleNavbar);
    };
  }, []);

  return (
    <Layout seo={seo} header_setup={navbar_setup} isNavbarHidden={isHidden}>
      <section className="homepage-hero" ref={homeRef}>
        <div className="homepage-hero__video-wrapper">
          {home_page_data?.heroVideos.map(({ videoLink }, index) => {
            console.log(videoLink);
            return (
              <video key={index} className={index === slideIndex ? 'active' : ''} width="100%" height="100%" playsInline muted autoPlay loop >
                <source src={videoLink} type="video/mp4" />
              </video>
              )
            })
          }
          <div className="homepage-hero__title">
            <p>{home_page_data.heroTitle}</p>
            <h1>{home_page_data.heroSubtitle}</h1>
          </div>
          <div className="homepage-hero__bulletpoints">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 offset-xl-2 col-xl-2">
                  <div className="homepage-hero__bulletpoints__title">OGLĄDASZ TERAZ</div>
                </div>
                {home_page_data?.heroVideos.map((video, index) => {
                  return (
                    <div key={index} className="col-lg-3 col-xl-2">
                      <div className={index === slideIndex ? 'homepage-hero__bulletpoints__items active' : 'homepage-hero__bulletpoints__items'}>
                        <p>{video.videoTitle}</p>
                        <p>{video.videoClient}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomePortfolio portfolioData={home_page_data.portfolioSelection} />

      <Counter backgroundColor="#1B1717" fontColor="#FFFFFF" counterTitle={home_page_data.homeCounterTitle} counterData={home_page_data.homeCounterInfo} />
      
      <section className="homepage-meet">
        <LeftSideText content={homepage_meet_content} />
      </section>

      <ClientsSlider logos={home_page_data.logosAll} />
      <ImagesBar images={home_page_data.homeGallery} />

    </Layout>
  )
}

export const query = graphql`
  {
    homePage: allWpPage(filter: {databaseId: {eq: 78}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pageHome {
          heroSubtitle
          heroTitle
          heroVideos {
            videoClient
            videoLink
            videoTitle
          }
          homeGallery {
            galleryImg {
              altText
              sourceUrl
            }
          }
          logosAll {
            logosItem {
              altText
              sourceUrl
            }
          }
          logosTitle
          meetBtn
          meetHeading
          meetInfo
          meetLink
          meetText
          meetImg {
            altText
            sourceUrl
          }
          homeCounterTitle
          homeCounterInfo {
            counterNumber
            counterSubject
          }
          portfolioSelection {
            ... on WpPortfolioItem {
              id
              portfolio {
                portfolioDesc
                portfolioTitle
                portfolioVideo
                portfolioAdditional
                portfolioImg {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HomePage;
