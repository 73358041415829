import "./styles.scss";

import React, {useState} from "react";
import ModalVideo from "../../../components/ModalVideo";

const HomePortfolioItem = ({ item }) => {

  const {portfolioTitle, portfolioDesc, portfolioImg, portfolioAdditional, portfolioVideo} = item.portfolio;

  const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        {isOpen && <ModalVideo video_url={portfolioVideo} setIsOpen={setIsOpen} />}
      <div className="homepage-grid__col">
        <img
          className="homepage-grid__image img-fluid"
          src={portfolioImg.sourceUrl}
          alt={portfolioImg.altText}
        />
        <div className="image-cover">
          <div className="image-cover__title">{portfolioTitle}</div>
          <div className="image-cover__type">{portfolioDesc}</div>
        </div>
        <div className="image-hover" onClick={() => setIsOpen(true)}>
          <div className="image-hover__icon">
            <img
              className="homepage-grid__image img-fluid"
              src={require("assets/svg/play-filled.svg").default}
              alt=""
            />
          </div>
          <div className="image-hover__time">{portfolioAdditional}</div>
        </div>
      </div>
      </>
    );
  };
  
  export default HomePortfolioItem;